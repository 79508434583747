import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../api/axios";

const Activate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  let { activationCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const activateUser = async () => {
      const response = await axios.post("/activation/activate", {
        activationCode: activationCode,
      });

      if (response.status === 200) {
        setIsSuccess(true);
      }

      setIsLoading(false);
    };

    activateUser().catch((err) => console.log(err));
  }, [activationCode]);
  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        {isLoading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        ) : (
          <React.Fragment>
            {isSuccess ? (
              <div className="d-flex justify-content-center align-items-center">
                Aktivierung erfolgreich
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                Aktivierungslink ungültig
              </div>
            )}
            <div
              className="btn btn-secondary"
              onClick={() => navigate("/login")}
            >
              Zurück zum Login
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default Activate;
