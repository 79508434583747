
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Dashboard() {
  const { t, i18n } = useTranslation();
  const [userDetails, setUserDetails] = useState()
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await axiosPrivate.get(`/user/details`)
      const data = await response.data
      setUserDetails(data)
    }
    fetchUserData()
  }, [axiosPrivate])

  return (
    <div className="mt-3">
      <h1>{t("welcome")} {userDetails?.firstName + " " + userDetails?.lastName}</h1>
      <div>{t("dashboard_text_1")} {userDetails?.count} {t("dashboard_text_2")} {userDetails?.searchAmount} {t("dashboard_text_3")}</div>
    </div>
  );
}

export default Dashboard;
