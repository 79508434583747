import * as React from 'react';
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ImagesTable from '../components/tables/ImagesTable';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px dotted #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Images() {

    const { t, i18n } = useTranslation();
    const [selectedIds, setSelectedIds] = useState([]);
    const [images, setImages] = useState([])
    const axiosPrivate = useAxiosPrivate()

    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    })
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <span style={{ position: "relative", left: "15px", top: "-10px", color: "red" }} onClick={() => removeFile(file.name)}><i class="bi bi-x fs-3"></i></span>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    alt=''
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const fetchImages = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/image/get?offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setImages(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }


    useEffect(() => {
        fetchImages().catch(error => console.log(error))
    }, [page, pageSize])

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const deleteImages = async () => {
        await axiosPrivate.delete("/image/delete", { data: { ids: selectedIds } })
        setSelectedIds([])
        fetchImages()
    }

    const uploadImages = async (event) => {
        event.preventDefault()

        if (acceptedFiles.length + pageTotal > 500) {
            alert("Upload exceeds limit")
            return
        }
        setIsLoading(true)
        const formData = new FormData();

        acceptedFiles.forEach(file => {
            formData.append('image', file);
        })

        await axiosPrivate.post("image/createBucket", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        fetchImages()
        acceptedFiles.splice(0, acceptedFiles.length);
        setFiles([])
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    const removeFile = name => {
        setFiles(files => files.filter(file => file.name !== name))
    }

    return (
        <div>
            <div className="mt-3 row">
                <div className="col-12 col-xl-6 d-flex text-center">
                    <div className="dropzone rounded-2 m-2 p-4">
                        <form onSubmit={uploadImages}>
                            <div>
                                {t("images_dropzone_count")} {pageTotal} / 500
                            </div>
                            {/* <input name="image" type="file" accept="image/png, image/jpeg" onChange={e => setFile(e.target.files[0])} /> */}
                            {/* <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>{t("images_dropzone_info")}</p> :
                                        <p>{t("images_dropzone_info")}</p>
                                }
                                {files ? files.map((number) => {
                                    <>
                                        <span onClick={() => removeFile(number.name)}>x</span><p key={number.name}>{number.name}</p></>
                                }
                                ) : null}
                            </div> */}
                            <div {...getRootProps({ className: 'dropzone' })} style={{ border: "1px dashed" }}>
                                <input {...getInputProps()} />
                                <p className='p-3'>{t("images_dropzone_info")}</p>
                            </div>
                            <aside style={thumbsContainer}>
                                {thumbs}
                            </aside>
                            <br />
                            {files.length > 0 ? <button className='mt-2 btn btn-primary'>{t("images_button_upload")}</button> : <></>}
                        </form>

                    </div>
                    {selectedIds.length > 0 ?
                        <div className="m-2 mt-auto btn btn-primary" onClick={deleteImages}>{t("images_button_delete_selected")}</div>
                        :
                        <div className="m-2 mt-auto btn btn-primary inactive">{t("images_button_delete_selected")}</div>
                    }
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-between">

                </div>
            </div>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <ImagesTable images={images} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} />
            }
        </div>
    );
}

export default Images;