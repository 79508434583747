import * as React from "react";
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next";
import countriesv2 from "../../data/countriesv2.json";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function UserSettings() {
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate()
  const [customerData, setCustomerData] = React.useState()


  React.useEffect(() => {
    const fetchCustomerData = async () => {
      const response = await axiosPrivate.get("/user/customerData")
      const data = await response.data
      setCustomerData(data.user)
    }

    fetchCustomerData()
  }, [axiosPrivate])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const input = Object.fromEntries(formData)
    const response = await axiosPrivate.put("/user/customerData", input)
    if (response.status === 200) {
      toast.success(t("toast_update_success"))
    }
  }

  return (<>
    <form onSubmit={handleSubmit}>
      <div className='row d-flex justify-content-between ms-2 p-3 my-3 shadow-sm bg-body-tertiary border bg-white rounded-2'>
        <div className='col-md-12'>

          <div className="d-grid form-group mb-3">

            <label for="salutation-select">{t("salutation")}</label>
            <div className="d-flex">

              <select className="form-select" name="salutation" id="salutation-select" style={{ width: "100%", borderRadius: "5px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                <option value="MR" selected={customerData?.salutation === "MR" ? "selected" : ""}>{t("MR")}</option>
                <option value="MS" selected={customerData?.salutation === "MS" ? "selected" : ""}>{t("MS")}</option>
              </select>
              <i className="p-2 bi bi-person-fill icon"></i>
            </div>
          </div>
          <div className="d-grid form-group mb-3">
            <label for="Title">{t("title")}</label>
            <div className="d-flex">
              <input
                type="text"
                name="title"
                className="form-control"
                id="NameTitle"
                aria-describedby="titelHelp"
                placeholder={t("title")}
                defaultValue={customerData?.title}
                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
              />
              {/* <select name="title" id="title-select" style={{ width: "100%", borderRadius: "5px", border: "none" }}>
                  <option value="Doktor">Doktor</option>
                </select>*/}
              <i className="p-2 bi bi-person-fill icon"></i>
            </div>
          </div>
          <label for="Title">{t("firstName")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="firstName"
              className="form-control"
              id="FirstName"
              aria-describedby="vornameHelp"
              placeholder={t("firstName")}
              defaultValue={customerData?.firstName}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("lastName")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="lastName"
              className="form-control"
              id="LastName"
              placeholder={t("lastName")}
              defaultValue={customerData?.lastName}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("website")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="website"
              className="form-control"
              id="LastName"
              placeholder={t("website")}
              defaultValue={customerData?.website}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("company")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="companyName"
              className="form-control"
              id="LastName"
              placeholder={t("company")}
              defaultValue={customerData?.companyName}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("street")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="street"
              className="form-control"
              id="Street"
              aria-describedby="vornameHelp"
              placeholder={t("street")}
              defaultValue={customerData?.street}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("houseNumber")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="houseNumber"
              className="form-control"
              id="HouseNumber"
              aria-describedby="hausnummerHelp"
              placeholder={t("houseNumber")}
              defaultValue={customerData?.houseNumber}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("postalCode")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="postalCode"
              className="form-control"
              id="PostalCode"
              aria-describedby="vornameHelp"
              placeholder={t("postalCode")}
              defaultValue={customerData?.postalCode}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          <label for="Title">{t("city")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="city"
              className="form-control"
              id="City"
              aria-describedby="vornameHelp"
              placeholder={t("city")}
              defaultValue={customerData?.city}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div>
          {/* <label for="Title">{t("country")}</label>
          <div className="d-flex form-group mb-3">
            <input
              type="text"
              name="country"
              className="form-control"
              id="Country"
              aria-describedby="vornameHelp"
              placeholder={t("country")}
              defaultValue={customerData?.country}
            />
            <i className="p-2 bi bi-person-fill icon"></i>
          </div> */}
          <div className="d-grid form-group mb-3">

            <label for="salutation-select">{t("country")}</label>
            <div className="d-flex">

              <select op className="form-select" name="country" id="salutation-select" style={{ width: "100%", borderRadius: "5px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                {countriesv2.map(country => (
                  <option selected={country.alpha2 === customerData?.country ? "selected" : ""} key={country.alpha2} value={country.alpha2}>{i18n.language === "de" ? country.de : country.en}</option>
                ))}
              </select>
              <i className="p-2 bi bi-person-fill icon"></i>
            </div>
          </div>
        </div>
        <button className="btn btn-primary d-block mt-2">{t("submit")}</button>
      </div>
    </form>
  </>
  );
}

export default UserSettings;
