import * as React from "react";
import { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

function ProfileSettings() {

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDelete = async () => {
    setShow(false)
    const response = await axiosPrivate.delete("/user/delete");
    if (response.status === 200) {
      await axiosPrivate.get("/auth/logout");
      setAuth({});
      navigate('/login');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const input = Object.fromEntries(formData)
    if (input.newPassword === input.newPasswordSubmit) {
      try {
        const response = await axiosPrivate.post("/user/changePassword", {
          oldPassword: input.oldPassword,
          newPassword:
            input.newPassword
        });
        setErrorMessage("")
        if (response.status === 200) {
          toast.success("Updated successfully")
        }
        e.target.reset()
      } catch (error) {

        if (error.response.status === 401) {
          setErrorMessage("Altes Passwort ist falsch")
        }
      }
    } else {
      setErrorMessage("Passwörter stimmen nicht überein")
    }

  }

  return (
    <>

      <div className="d-flex flex-column">
        <div className="bg-white shadow-sm p-3 mb-2 bg-body-tertiary rounded-2 ">
          <div className="d-flex flex-column">
            <h3 className="mt-3">{t("delete_profile")}</h3>
            <div className="btn btn-danger" onClick={handleShow}><i className="bi bi-trash3"></i></div>
          </div>
          <h3 className="mt-3">{t("change_password")}</h3>
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
              <label className="mt-2">{t("settings_profile_oldPassword")}</label>
              <input className="border rounded-2" type="password" name="oldPassword" />
              <label className="mt-2">{t("settings_profile_newPassword")}</label>
              <input className="border rounded-2" type="password" name="newPassword" />
              <label className="mt-2">{t("settings_profile_newPasswordConfirm")}</label>
              <input className="border rounded-2" type="password" name="newPasswordSubmit" />
            </div>
            <span style={{ color: "red" }}>{errorMessage}</span>
            <button className="btn btn-primary d-block mt-2">{t("submit")}</button>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("profile_delete_warning")}</Modal.Body>
        <Modal.Footer>
          <div className="btn btn-danger" onClick={handleDelete}>
            {t("delete")}
          </div>
          <div className="btn btn-secondary" onClick={handleClose}>
            {t("cancel")}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProfileSettings;
