import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import axios from "../../api/axios";
const REGISTER_URL = "/auth/signup";

const Register = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("")
    setPasswordError("")
    try {
      const formData = new FormData(e.target);

      const user = Object.fromEntries(formData);

      if (user.password === user.passwordConfirm) {
        await axios.post(REGISTER_URL, user);
        setSuccess(true)
      } else {
        setPasswordError("Passwords do not match")
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        setEmailError("Email already in use")
      } else {
        alert("No backend connection")
      }
    }
  };

  return (
    <>

      <div className="position-absolute container-fluid align-center">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>{t("login_message")}</p>
        </div>
        <div className="container mt-5">
          {success ?
            <div>{t("register_success")}</div>
            :
            <form onSubmit={handleSubmit}>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">{t("salutation")}</label>
                <div className="d-flex">
                  <select className="form-select" name="salutation" id="salutation-select" style={{ width: "100%", borderRadius: "5px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                    <option value="MR">{t("MR")}</option>
                    <option value="MS">{t("MS")}</option>
                  </select>
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">{t("title")}</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    id="Title"
                    aria-describedby="titelHelp"
                    placeholder={t("title")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                  />
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">{t("firstName")}</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="FirstName"
                    aria-describedby="vornameHelp"
                    placeholder={t("firstName")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">{t("lastName")}</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="LastName"
                    placeholder={t("lastName")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mail">
                <label for="salutation-select">{t("email")}</label><span style={{ color: "red" }}>{emailError}</span>
                <div className="d-flex">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="username"
                    autocomplete="username"
                    aria-describedby="emailHelp"
                    placeholder={t("email")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-envelope-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mt-3 password">
                <label for="salutation-select">{t("password")}</label>
                <div className="d-flex">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    id="password"
                    autocomplete="new-password"
                    placeholder={t("password")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-key-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mt-3 password">
                <label for="salutation-select">{t("password_confirm")}</label><span style={{ color: "red" }}>{passwordError}</span>
                <div className="d-flex">
                  <input
                    type="password"
                    name="passwordConfirm"
                    className="form-control"
                    id="password"
                    autocomplete="new-password"
                    placeholder={t("password_confirm")}
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-key-fill icon"></i>
                </div>
              </div>
              <div className="d-flex gap-2 mt-3">
                <div className="fi fi-de" onClick={() => changeLanguage("de")}></div>
                <div className="fi fi-gb" onClick={() => changeLanguage("en")}></div>
              </div>

              <div className="d-grid gap-2">
                <button className="btn btn-secondary mt-3">{t("login_register")}</button>
              </div>
            </form>
          }

          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/login")}
            >
              {t("back_to_login")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
