import * as React from "react";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { axiosPrivate } from "../../api/axios";
function SearchSettings() {
  const { t } = useTranslation();
  const [selectedSearchInterval, setSelectedSearchInterval] = useState([]);
  // const [selectedWhitelistIds, setSelectedWhitelistIds] = useState([]);
  // const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  // const [whitelistItems, setWhitelistItems] = useState([]);
  // const [blacklistItems, setBlacklistItems] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [whitelistPage, setWhitelistPage] = useState(0)
  // const [whitelistPageSize, setWhitelistPageSize] = useState(10)
  // const [whitelistPageTotal, setWhitelistPageTotal] = useState(10)
  // const [blacklistPage, setBlacklistPage] = useState(0)
  // const [blacklistPageSize, setBlacklistPageSize] = useState(10)
  // const [blacklistPageTotal, setBlacklistPageTotal] = useState(10)
  // const [whitelistError, setWhitelistError] = useState("")
  // const [blacklistError, setBlacklistError] = useState("")

  const fetchSearchInterval = async () => {
    const response = await axiosPrivate.get("/user/details");
    const data = await response.data;

    setSelectedSearchInterval(data.nextSearchTimespan)
  };

  // const fetchWhitelists = async () => {
  //   setIsLoading(false);
  //   const response = await axiosPrivate.get(`/whitelist/get?offset=${whitelistPage}&limit=${whitelistPageSize}`);
  //   const data = await response.data.rows;

  //   setWhitelistItems(data);
  //   setWhitelistPageTotal(response.data.count);
  //   setIsLoading(false);
  // };

  // const fetchBlacklists = async () => {
  //   setIsLoading(true);
  //   const response = await axiosPrivate.get(`/blacklist/get?offset=${blacklistPage}&limit=${blacklistPageSize}`);
  //   const data = await response.data.rows;

  //   setBlacklistItems(data);
  //   setBlacklistPageTotal(response.data.count);
  //   setIsLoading(false);
  // };

  useEffect(() => {
    fetchSearchInterval().catch((error) => console.log(error));
  }, []);

  // useEffect(() => {
  //   fetchWhitelists().catch((error) => console.log(error));
  // }, [whitelistPage, whitelistPageSize]);

  // useEffect(() => {
  //   fetchBlacklists().catch((error) => console.log(error));
  // }, [blacklistPage, blacklistPageSize]);

  // const getSelectedWhitelistIds = (data) => {
  //   setSelectedWhitelistIds(data);
  // };
  // const getSelectedBlacklistIds = (data) => {
  //   setSelectedBlacklistIds(data);
  // };

  // const whitelistPageChange = (model) => {
  //   setWhitelistPage(model.page)
  //   setWhitelistPageSize(model.pageSize)
  // }

  // const blacklistPageChange = (model) => {
  //   setBlacklistPage(model.page)
  //   setBlacklistPageSize(model.pageSize)
  // }

  // const addWhitelist = async (event) => {
  //   event.preventDefault();
  //   const fd = new FormData(event.target);
  //   const input = Object.fromEntries(fd.entries());
  //   try {
  //     await axiosPrivate.post("/whitelist/create", input);
  //     event.target.reset();
  //     fetchWhitelists().catch((error) => console.log(error));
  //     setWhitelistError("")
  //   } catch (error) {
  //     setWhitelistError("URL already whitelisted")
  //   }
  // };

  // const addBlacklist = async (event) => {
  //   event.preventDefault();
  //   const fd = new FormData(event.target);
  //   const input = Object.fromEntries(fd.entries());
  //   try {
  //     await axiosPrivate.post("/blacklist/create", input);
  //     event.target.reset();
  //     fetchBlacklists().catch((error) => console.log(error));
  //     setBlacklistError("")
  //   } catch (error) {
  //     setBlacklistError("URL already blacklisted")
  //   }
  // };

  // const deleteBlacklists = async () => {
  //   await axiosPrivate.delete("/blacklist/delete", {
  //     data: { ids: selectedBlacklistIds },
  //   });
  //   setSelectedBlacklistIds([]);
  //   fetchBlacklists().catch((error) => console.log(error));
  // };

  // const deleteWhitelists = async () => {
  //   await axiosPrivate.delete("/whitelist/delete", {
  //     data: { ids: selectedWhitelistIds },
  //   });
  //   setSelectedWhitelistIds([]);
  //   fetchWhitelists().catch((error) => console.log(error));
  // };

  const submitSearchInterval = async (interval) => {
    setSelectedSearchInterval(interval)
    await axiosPrivate.put("/user/setInterval", { searchInterval: interval })
  }

  return (
    <div className="d-flex flex-column">
      {/* <div className="bg-white shadow-sm p-2 mb-2 bg-body-tertiary rounded-2 ">
        <h1>Aktueller Zeitraum</h1>
        <p>Offener Betrag</p>
        <p>Abrechnungsdatum</p>
      </div> */}
      <div className="my-2 p-3 shadow-sm p-2 mb-2 bg-body-tertiary border bg-white rounded-2">
        <h3>{t("settings_searchInterval")}</h3>
        <Dropdown >
          <Dropdown.Toggle id="dropdown-basic">
            {selectedSearchInterval === 7 ? t("settings_searchInterval_weekly") : t("settings_searchInterval_monthly")}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ opacity: "1" }} >
            {/* <Dropdown.Item onClick={() => submitSearchInterval(7)}>Wöchentlich</Dropdown.Item> */}
            <Dropdown.Item onClick={() => submitSearchInterval(31)}>{t("settings_searchInterval_monthly")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* {isLoading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      ) : (
        <>
          <div className="my-2 p-2 shadow-sm p-2 mb-2 bg-body-tertiary border bg-white rounded-2">
            <h1>Whitelist</h1>
            <form onSubmit={addWhitelist}>
              <div className="d-flex mb-2">
                <input className="border rounded-2 me-2" type="text" name="url" required />
                <button
                  className="btn btn-primary mx-1"
                  style={{ background: "#79E945" }}
                >
                  <i className="bi bi-plus fs-6"></i>
                </button>
                {selectedWhitelistIds.length > 0 ? (
                  <div className="btn btn-primary mx-1" onClick={deleteWhitelists}>
                    <i className="bi bi-trash3 fs-6"></i>
                    { }
                  </div>
                ) : (
                  <div className="btn btn-primary inactive mx-1">
                    <i className="bi bi-trash3 fs-6"></i>
                  </div>
                )}
              </div>
              <div className="mb-2" style={{ color: "red" }}>{whitelistError}</div>
            </form>
            <WhitelistTable
              whitelistItems={whitelistItems}
              getSelectedIds={getSelectedWhitelistIds} pageChange={whitelistPageChange} page={whitelistPage} pageSize={whitelistPageSize} pageTotal={whitelistPageTotal}
            />
          </div>
          <div className="my-2 p-2 border shadow-sm p-2 mb-2 bg-body-tertiary bg-white rounded-2">
            <h1>Blacklist</h1>
            <form onSubmit={addBlacklist}>
              <div className="d-flex mb-3">
                <input className="border rounded-2 me-2" type="text" name="url" required />
                <button
                  className="btn btn-primary mx-1"
                  style={{ background: "#79E945" }}
                >
                  <i className="bi bi-plus fs-6"></i>
                </button>
                {selectedBlacklistIds.length > 0 ? (
                  <div className="btn btn-primary mx-1" onClick={deleteBlacklists}>
                    <i className="bi bi-trash3 fs-6"></i>
                    { }
                  </div>
                ) : (
                  <div className="btn btn-primary inactive mx-1">
                    <i className="bi bi-trash3 fs-6"></i>
                  </div>
                )}
              </div>
              <div className="mb-2" style={{ color: "red" }}>{blacklistError}</div>
            </form>
            <BlacklistTable
              blacklistItems={blacklistItems}
              getSelectedIds={getSelectedBlacklistIds} pageChange={blacklistPageChange} page={blacklistPage} pageSize={blacklistPageSize} pageTotal={blacklistPageTotal}
            />
          </div>
        </>
      )} */}
    </div>
  );
}

export default SearchSettings;
