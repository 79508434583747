import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function NavbarTop() {
  const { t, i18n } = useTranslation();
  const { setAuth } = useContext(AuthContext);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState(false)
  const axiosPrivate = useAxiosPrivate();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const logout = async () => {
    await axiosPrivate.get("/auth/logout");
    setAuth({});
    navigate('/login');
  }


  return (
    <div className='d-flex mx-5'>

      <Navbar expand="lg" className="" expanded={navExpanded} onToggle={() => setNavExpanded(!navExpanded)}>
        <Container>

          {/* <Navbar.Brand><Link className="navbar-brand" to="/" style={{ color: "#F1F8E8" }}><img src='weplanner 1.png' alt='...' /></Link></Navbar.Brand> */}

          <Navbar.Toggle className='custom-toggler' aria-controls="basic-navbar-nav" style={{ borderRadius: "20px" }} />


          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink onClick={() => setNavExpanded(false)} to="/" className="d-flex nav-link" end><img src="/icons/DashboardNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Dashboard</span></NavLink>
              <NavLink onClick={() => setNavExpanded(false)} to="/results" className="d-flex nav-link"><img src="/icons/TrefferNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("result")}</span></NavLink>
              <NavLink onClick={() => setNavExpanded(false)} to="/images" className="d-flex nav-link"><img src="/icons/BilderNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("images")}</span></NavLink>
              <NavLink onClick={() => setNavExpanded(false)} to="/settings" className="d-flex nav-link"><img src="/icons/SettingsIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("settings")}</span></NavLink>
              {auth?.role === "admin" ?
                <NavLink onClick={() => setNavExpanded(false)} to="/admin" className="d-flex nav-link"><img src="/icons/ProfileIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Admin</span></NavLink> : <></>}
              <NavLink to="/logout" onClick={logout} className="d-flex nav-link"><img src="/icons/LogoutIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("logout")}</span></NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-flex gap-2">
        <div className="fi fi-de" onClick={() => changeLanguage("de")}></div>
        <div className="fi fi-gb" onClick={() => changeLanguage("en")}></div>
      </div>
    </div>
  )
}

export default NavbarTop