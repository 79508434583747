import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function MainNavigation() {
    const { t, i18n } = useTranslation();
    const { setAuth } = useContext(AuthContext);
    const { auth } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    const logout = async () => {
        await axiosPrivate.get("/auth/logout");
        setAuth({});
        navigate('/login');
    }

    return (
        <aside id="sidebar" className="js-sidebar">
            <div className="p-2">
                <div>
                    <NavLink to="/" className="d-flex text-decoration-none mt-1 align-items-center text-white">
                        <span className="fs-4 d-none d-sm-inline text-black"><NavLink to="/" className="nav-link" end>UNITED CREATIVES</NavLink></span>
                    </NavLink>
                </div>
                <div>
                    <ul className="nav nav-pills flex-column mt-3">
                        <li className="nav-item py-2">
                            <NavLink to="/" className="nav-link" end><img src="/icons/DashboardNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Dashboard</span></NavLink>
                        </li>
                        <li className="nav-item py-2">
                            <NavLink to="/results" className="nav-link"><img src="/icons/TrefferNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("result")}</span></NavLink>
                        </li>
                        <li className="nav-item py-2">
                            <NavLink to="/images" className="nav-link"><img src="/icons/BilderNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("images")}</span></NavLink>
                        </li>
                        {/* <li className="nav-item py-2">
                        <NavLink to="/invoices" className="nav-link"><img src="/icons/BillIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Rechnungen</span></NavLink>
                    </li>                                      */}
                        <li className="nav-item py-2">
                            <NavLink to="/settings" className="nav-link"><img src="/icons/SettingsIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("settings")}</span></NavLink>
                        </li>
                        {auth?.role === "admin" ?
                            <>
                                <li className="nav-item py-2">
                                    <NavLink to="/admin" className="nav-link"><img src="/icons/ProfileIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Admin</span></NavLink>
                                </li>
                            </> :
                            <></>
                        }
                        <li className="nav-item py-2">
                            <NavLink to="/logout" onClick={logout} className="nav-link"><img src="/icons/LogoutIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>{t("logout")}</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="d-flex gap-2 mt-2 ms-3">
                    <div className="fi fi-de" onClick={() => changeLanguage("de")}></div>
                    <div className="fi fi-gb" onClick={() => changeLanguage("en")}></div>
                </div>
            </div>


        </aside>

    )
}

export default MainNavigation;