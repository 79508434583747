import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import axios from "../../api/axios";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState(false)

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);

      const input = Object.fromEntries(formData);
      const response = await axios.post(
        "/activation/requestPasswordReset",
        input
      );

      if (response.status === 200) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        {success ? <>
          <div>{t("reset_success")}</div>
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/login")}
            >
              {t("back_to_login")}
            </button>
          </div></> : <>
          <div className="d-flex justify-content-center fw-bold fs-3">
            <p>{t("login_forgot_password")}</p>
          </div>
          <div className="container mt-5">
            <form onSubmit={handleSubmit}>
              <div className="d-flex form-group mail">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={t("email")}
                />
                <i className="p-3 bi bi-envelope-fill icon"></i>
              </div>
              <div className="d-flex gap-2 mt-2 ms-1">
                <div className="fi fi-de" onClick={() => changeLanguage("de")}></div>
                <div className="fi fi-gb" onClick={() => changeLanguage("en")}></div>
              </div>
              <div className="d-grid gap-2">
                <button className="btn btn-secondary mt-3">{t("submit")}</button>
              </div>
            </form>
            <div className="d-grid gap-2">
              <button
                className="btn btn-secondary mt-2"
                style={{
                  color: "#70AE66",
                  backgroundColor: "transparent",
                  border: "1px solid #70AE66",
                }}
                onClick={() => navigate("/login")}
              >
                {t("back_to_login")}
              </button>
            </div>
          </div></>}
      </div>
    </>
  );
};

export default ForgotPassword;
