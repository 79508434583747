import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import axios from "../../api/axios";

const SetNewPasswordNewCustomer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { activationCode } = useParams();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      formData.append("activationCode", activationCode)
      const input = Object.fromEntries(formData);

      if (input.newPassword !== input.newPasswordConfirm) {
        return;
      }

      const response = await axios.post("/activation/setPassword", input);
      if (response.status === 200) {
        navigate("/login")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>Neues Passwort festlegen</p>
        </div>
        <div className="container mt-5">
          <form onSubmit={handleSubmit}>
            <div className="d-flex mb-2 form-group mail">
              <input
                type="password"
                name="newPassword"
                className="form-control"
                id="exampleInputPassword"
                aria-describedby="passwordHelp"
                placeholder="Neues Passwort"
              />
              <i className="p-3 bi bi-key-fill icon"></i>
            </div>
            <div className="d-flex mb-2 form-group mail">
              <input
                type="password"
                name="newPasswordConfirm"
                className="form-control"
                id="exampleInputPasswordConfirm"
                aria-describedby="passwordHelp"
                placeholder="Neues Passwort bestätigen"
              />
              <i className="p-3 bi bi-key-fill icon"></i>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-secondary mt-1">
                Neues Passwort festlegen
              </button>
            </div>
          </form>
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/login")}
            >
              Zum Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetNewPasswordNewCustomer;
