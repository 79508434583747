import { jwtDecode } from "jwt-decode";
import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/auth/refresh', {
            withCredentials: true
        });
        const token = jwtDecode(response.data.token)
        setAuth(prev => {
            return { ...prev, accessToken: response.data.token, role: token.role, eligible: token.eligible }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;
