import * as React from 'react';
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';
import ImagesTable from '../components/tables/ImagesTable';
import ResultsTable from '../components/tables/ResultsTable';
import UserTable from '../components/tables/UserTable';
import countries from "../data/countries.json";
import useAxiosPrivate from "../hooks/useAxiosPrivate";



function Admin() {
    const { t, i18n } = useTranslation();
    const [selectedIds, setSelectedIds] = useState([]);
    const [users, setUsers] = useState([])
    const [images, setImages] = useState([])
    const [results, setResults] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const axiosPrivate = useAxiosPrivate()
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)
    const [userPage, setUserPage] = useState(0)
    const [userPageSize, setUserPageSize] = useState(10)
    const [userPageTotal, setUserPageTotal] = useState(10)
    const [toggle, setToggle] = useState(1);
    const [addCustomer, setAddCustomer] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [timer, setTimer] = useState(null)

    const fetchImages = async (userId) => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/admin/getImages?userId=${userId}&offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setImages(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    const fetchResults = async (userId) => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/admin/getResults?userId=${userId}&offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setResults(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    // useEffect(() => {
    //     fetchImages().catch(error => console.log(error))
    //     fetchResults().catch(error => console.log(error))
    // }, [page, pageSize, selectedUser])

    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true)
            const response = await axiosPrivate.get(`/admin/getAllUsers?offset=${userPage}&limit=${userPageSize}&searchTerm=${searchTerm}`)
            const data = await response.data.rows
            setUsers(data)
            setUserPageTotal(response.data.count)
            setIsLoading(false)
        }

        fetchUsers()
    }, [userPage, userPageSize, searchTerm, axiosPrivate])

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const getSelectedUser = (data) => {
        setSelectedIds(data)
        fetchUserById(data)
    }

    const deleteImages = async () => {
        await axiosPrivate.delete(`/admin/deleteImage?userId=${selectedUser ? selectedUser.id : ""}`, { data: { ids: selectedIds } })
        setSelectedIds([])
        fetchImages()
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    const userPageChange = (model) => {
        setUserPage(model.page)
        setUserPageSize(model.pageSize)
    }

    const findUser = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const input = Object.fromEntries(formData)
        fetchUserById(input.email)
    }

    const fetchUserById = async (id) => {
        const response = await axiosPrivate.get(`admin/getUser?id=${id}`)
        const data = await response.data
        setSelectedUser(data)
        fetchResults(data.id)
        fetchImages(data.id)
        // setImages(data?.images ? data.images : null)
        // setResults(data?.results ? data.results : null)
    }

    const changeRole = async (role) => {
        await axiosPrivate.put("admin/editUser", {
            role: role,
            active: selectedUser.active,
            eligible: selectedUser.active,
            id: selectedUser ? selectedUser.id : ""
        })
        fetchUserById(selectedUser.email)
    }

    const toggleActive = async (e) => {
        await axiosPrivate.put("admin/editUser", {
            role: selectedUser.role,
            active: e.target.checked,
            eligible: selectedUser.active,
            id: selectedUser ? selectedUser.id : ""
        })
        fetchUserById(selectedUser.id)
    }

    const toggleEligible = async (e) => {
        await axiosPrivate.put("admin/editUser", {
            role: selectedUser.role,
            active: selectedUser.active,
            eligible: e.target.checked,
            id: selectedUser ? selectedUser.id : ""
        })
        fetchUserById(selectedUser.id)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData(e.target);

            const user = Object.fromEntries(formData);
            await axiosPrivate.post("admin/createUser", user);
            setAddCustomer(false)
        } catch (err) {
            if (err?.response?.status === 422) {
                setEmailError("Email already in use")
            }
        }
    };

    const handleTextEdit = (event) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(async () => {
                setSearchTerm(event.target.value)
            }, 500)
        );
    }

    return (
        <div>
            <div className="d-flex flex-column">
                <div>
                    <h2>{t("user")}</h2>
                    <input type='text' placeholder='Suche' onChange={handleTextEdit} className="border rounded-2 me-2 mb-2" />
                    <UserTable users={users} getSelectedUser={getSelectedUser} pageChange={userPageChange} page={userPage} pageSize={pageSize} pageTotal={pageTotal} />
                </div>
                <div className='mt-2 mx-2'>
                    <label>{t("new_customer")}</label>
                    <br />
                    <button
                        className="btn btn-primary"
                        style={{ background: "#79E945" }}
                        onClick={() => setAddCustomer(true)}
                    >
                        <i className="bi bi-plus fs-6"></i>
                    </button>
                </div>
                {addCustomer ?
                    <React.Fragment>
                        <form onSubmit={handleSubmit}>
                            <div className='row d-flex justify-content-between ms-2 p-2 my-3 shadow-sm bg-body-tertiary border bg-white rounded-2'>
                                <div className='col-md-12'>
                                    <span style={{ color: "red" }}>{emailError}</span>
                                    <label for="salutation-select">{t("email")}</label>
                                    <div className="d-flex form-group mail mb-3">
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            id="Email1"
                                            aria-describedby="emailHelp"
                                            placeholder={t("email")}
                                        />
                                        <i className="p-2 bi bi-envelope-fill icon"></i>
                                    </div>
                                    <div className="d-grid form-group mb-3">

                                        <label for="salutation-select">{t("salutation")}</label>
                                        <div className="d-flex">
                                            <select className="form-select" name="salutation" id="salutation-select" style={{ width: "100%", borderRadius: "5px", border: "none", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                                                <option value="MR">Herr</option>
                                                <option value="MS">Frau</option>
                                            </select>
                                            <i className="p-2 bi bi-person-fill icon"></i>
                                        </div>
                                    </div>
                                    <div className="d-grid form-group mb-3">
                                        <label for="salutation-select">{t("title")}</label>
                                        <div className="d-flex">
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control"
                                                id="Title"
                                                aria-describedby="titelHelp"
                                                placeholder={t("title")}
                                                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                                            />
                                            {/* <select name="title" id="title-select" style={{ width: "100%", borderRadius: "5px", border: "none" }}>
                  <option value="Doktor">Doktor</option>
                </select>*/}
                                            <i className="p-2 bi bi-person-fill icon"></i>
                                        </div>
                                    </div>
                                    <label for="salutation-select">{t("firstName")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            id="FirstName"
                                            aria-describedby="vornameHelp"
                                            placeholder={t("firstName")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <label for="salutation-select">{t("lastName")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="form-control"
                                            id="LastName"
                                            placeholder={t("lastName")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <label for="salutation-select">{t("street")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control"
                                            id="Street"
                                            aria-describedby="vornameHelp"
                                            placeholder={t("street")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <label for="salutation-select">{t("houseNumber")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="houseNumber"
                                            className="form-control"
                                            id="HouseNumber"
                                            aria-describedby="hausnummerHelp"
                                            placeholder={t("houseNumber")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <label for="salutation-select">{t("postalCode")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-control"
                                            id="PostalCode"
                                            aria-describedby="vornameHelp"
                                            placeholder={t("postalCode")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <label for="salutation-select">{t("city")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            id="City"
                                            aria-describedby="vornameHelp"
                                            placeholder={t("city")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    {/* <label for="salutation-select">{t("country")}</label>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-control"
                                            id="Country"
                                            aria-describedby="vornameHelp"
                                            placeholder={t("country")}
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div> */}
                                    <div className="d-grid form-group mb-3">

                                        <label for="salutation-select">{t("country")}</label>
                                        <div className="d-flex">

                                            <select op className="form-select" name="country" id="salutation-select" style={{ width: "100%", borderRadius: "5px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                                                {countries.map(country => (
                                                    <option key={country.code} value={country.code}>{country.name}</option>
                                                ))}
                                            </select>
                                            <i className="p-2 bi bi-person-fill icon"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            id="FirstName"
                                            aria-describedby="vornameHelp"
                                            placeholder="IBAN"
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="form-control"
                                            id="LastName"
                                            placeholder="BIC"
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control"
                                            id="Street"
                                            aria-describedby="vornameHelp"
                                            placeholder="Kontoinhaber"
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-control"
                                            id="PostalCode"
                                            aria-describedby="vornameHelp"
                                            placeholder="Bank"
                                        />
                                        <i className="p-2 bi bi-person-fill icon"></i>
                                    </div>

                                </div> */}
                                <button type="button" className="btn btn-secondary mt-3" onClick={() => setAddCustomer(false)}>{t("cancel")}</button>
                                <button type="submit" className="btn btn-secondary mt-3">{t("submit")}</button>

                            </div>
                        </form>
                    </React.Fragment>
                    : <></>}

                {selectedUser ?
                    <div className='d-flex justify-content-between  p-2 my-3 shadow-sm rounded-2' style={{ background: "rgba(255,255,255,.5)" }}>
                        {/* <form onSubmit={findUser}>
                        <label className='mx-2'>User Email</label>
                        <br />
                        <input className="border rounded-2 mx-2 p-2" type="text" name="email" />
                        <button
                            className="btn btn-primary"
                            style={{ background: "#79E945" }}
                        >
                            <i className="bi bi-search fs-6"></i>
                        </button>
                    </form> */}
                        <>
                            <div className='mt-2'>
                                <label>{t("name")}</label>
                                <p>{selectedUser?.firstName + " " + selectedUser?.lastName}</p>
                            </div>
                            <div className='mt-2'>
                                <label>{t("interval")}</label>
                                <p>{selectedUser?.nextSearchTimespan === 7 ? "Wöchentlich" : t("settings_searchInterval_monthly")}</p>
                            </div>
                            <div className='mt-2'>
                                <label>{t("role")}</label>
                                <Dropdown >
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" >
                                        {selectedUser?.role}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item value="user" onClick={() => changeRole("user")}>user</Dropdown.Item>
                                        <Dropdown.Item value="admin" onClick={() => changeRole("admin")}>admin</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='mt-2'>
                                <label>{t("active")}</label>
                                <div className="form-check form-switch" onClick={toggleActive}>
                                    <input className="form-check-input fs-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={selectedUser?.active} />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label>{t("prosecution_active")}</label>
                                <div className="form-check form-switch" onClick={toggleEligible}>
                                    <input className="form-check-input fs-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={selectedUser?.eligible} />
                                </div>
                            </div>
                        </>
                    </div>
                    : <></>}

                {isLoading ?
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                    </div>
                    :
                    <div>
                        <div className='mt-3 tab'>

                            <ul className='d-flex'>
                                <li className={toggle === 1 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(1)}>{t("images")}</li>
                                <li className={toggle === 2 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(2)}>{t("result")}</li>
                            </ul>
                            <>
                                {/* <div className="col-12 col-xl-6 d-flex text-center mb-2">
                                    {selectedIds?.length > 0 && toggle === 1 ?
                                        <div className="mt-auto btn btn-primary" onClick={deleteImages}>Ausgewählte Bilder löschen</div>
                                        :
                                        <div className="mt-auto btn btn-primary inactive">Ausgewählte Bilder löschen</div>
                                    }
                                </div> */}
                                <div className={toggle === 1 ? "show-content" : "tab-content"}>
                                    {toggle === 1 ?
                                        <ImagesTable images={images} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} /> : <></>}
                                </div>
                                <div className={toggle === 2 ? "show-content" : "tab-content"}>
                                    {toggle === 2 ?
                                        <ResultsTable results={results} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"admin"} /> : <></>}
                                </div>
                            </>
                        </div>
                    </div>

                }
            </div>
        </div>
    );
}

export default Admin;