import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "../../api/axios";

const EnterCode = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      const input = Object.fromEntries(formData);

      const response = await axios.post("activation/verifyResetCode", input);
      if (response.status === 200) {
        navigate("/setnewpassword", {
          state: {
            code: response.data.newResetCode,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const requestNewCode = async () => {
    try {
      await axios.post("/activation/requestPasswordReset", {
        email: state.input.email,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>Passwort vergessen?</p>
        </div>
        <p>Ein 4-stelliger Code wurde an Ihre Email Adresse gesendet</p>
        <div className="container mt-5">
          <form onSubmit={handleSubmit}>
            <div className="form-group mail">

              <input
                type="text"
                name="passwordResetCode"
                className="form-control"
                id="exampleInputCode"
                aria-describedby="verifyHelp"
                placeholder="4-Stelligen Code eingeben"
              />
              <p className="ps-3 mt-2">
                Code nicht erhalten? &nbsp;
                <span className="sendAgain" onClick={requestNewCode}>
                  Erneut senden
                </span>
              </p>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-secondary mt-1">Code bestätigen</button>
            </div>
          </form>
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/login")}
            >
              Zum Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterCode;
