import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ResultsTable from '../components/tables/ResultsTable';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Results() {
    const { t, i18n } = useTranslation();
    const [selectedIds, setSelectedIds] = useState([]);
    const [results, setResults] = useState([])
    const axiosPrivate = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)
    const [toggle, setToggle] = useState(1);

    const { auth } = useAuth();

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const fetchResults = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/result/getByUser?offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setResults(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchResults().catch(error => console.log(error))
    }, [page, pageSize])

    const archiveResults = async () => {
        await axiosPrivate.put("/result/archive", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const licenseResults = async () => {
        await axiosPrivate.put("/result/license", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const pursueResults = async () => {
        await axiosPrivate.put("/result/pursue", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const undoResults = async () => {
        await axiosPrivate.put("/result/undo", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    const toggleTab = (tab) => {
        setToggle(tab)
        fetchResults()
    }

    return (
        <div>
            <div>

                <div className='mt-3 tab'>
                    <ul className='d-flex'>
                        <li className={toggle === 1 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(1)}>{t("results_tab_results")}</li>
                        <li className={toggle === 2 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(2)}>{t("results_tab_deleted")}</li>
                        <li className={toggle === 4 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(4)}>{t("results_tab_licensed")}</li>
                        {auth?.eligible ? <li className={toggle === 3 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(3)}>{t("results_tab_prosecuted")}</li> : <></>}
                    </ul>
                    {toggle === 1 ?
                        <div div className="row">
                            <div className="col-12 d-flex gap-2">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-1 mt-auto btn btn-primary" onClick={archiveResults}>{t("results_button_delete_selected")}</div>
                                        <div className="m-1 mt-auto btn btn-primary" onClick={licenseResults}>{t("results_button_license_selected")}</div>
                                        {auth?.eligible ? <div className="m-1 mt-auto btn btn-primary" onClick={pursueResults}>{t("results_button_prosecute_selected")}</div> : <></>}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="mt-auto btn btn-primary inactive">{t("results_button_delete_selected")}</div>
                                        <div className="mt-auto btn btn-primary inactive" onClick={licenseResults}>{t("results_button_license_selected")}</div>
                                        {auth?.eligible ? <div className="mt-auto btn btn-primary inactive">{t("results_button_prosecute_selected")}</div> : <></>}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {toggle === 2 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {toggle === 4 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {toggle === 5 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">{t("results_button_undo_selected")}</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {/* {toggle === 3 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>Verfolgung rückgängig machen</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">Verfolgung rückgängig machen</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    } */}
                    <div>
                        {isLoading ?
                            <div className='d-flex justify-content-center'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                            :
                            (
                                <div className='mt-2'>
                                    <div className={toggle === 1 ? "show-content" : "tab-content"}>
                                        {toggle === 1 ?
                                            <ResultsTable results={results.filter(item => item.pursue !== true && item.archived !== true && item.licensed !== true && item.deleted !== true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"none"} /> : <></>}
                                    </div>
                                    <div className={toggle === 2 ? "show-content" : "tab-content"}>
                                        {toggle === 2 ?
                                            <ResultsTable results={results.filter(item => item.archived === true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"archived"} /> : <></>}
                                    </div>
                                    <div className={toggle === 3 ? "show-content" : "tab-content"}>
                                        {toggle === 3 ?
                                            <ResultsTable results={results.filter(item => item.pursue === true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"pursued"} /> : <></>}
                                    </div>
                                    <div className={toggle === 4 ? "show-content" : "tab-content"}>
                                        {toggle === 4 ?
                                            <ResultsTable results={results.filter(item => item.licensed === true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"licensed"} /> : <></>}
                                    </div>
                                </div>
                            )}
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Results;