import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivate } from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { formatDate } from '../../util/DateHelper';

function ResultsTable({ results, getSelectedIds, pageChange, page, pageSize, pageTotal, filter }) {
    const { t } = useTranslation();
    const [data, setData] = useState(results)
    const { auth } = useAuth();

    const columns = [
        {
            field: 'image', headerName: t("images_table_picture"), width: 110, renderCell: (params) => (
                <img src={params.row.url?.length > 0 ? params.row.url[0] : ""} width="100px" style={{ objectFit: "contain" }} alt='' />
            )
        },
        {
            field: 'result', headerName: t("result_table_resultPicture"), width: 110, renderCell: (params) => {
                return (
                    <img className="border" src={params.row.resultUrl} alt="Fundbild" width={100} />
                );
            },
        },
        {
            field: 'createdAt', headerName: t("images_table_uploadedAt"), width: 130, valueGetter: (params) => {
                return formatDate(params.row.createdAt)
            }
        },
        { field: 'resultUrl', headerName: t("result_table_resultUrl"), width: 400 },
        {
            field: 'website', headerName: t("result_table_websites"), width: 500, renderCell: (params) => {
                return (
                    <div className='d-grid py-2'>{params.row.websites ? params.row.websites.map(website => (<a href={website.url} key={website.id}>{website.url}</a>)) : <></>}</div>
                );
            },
        },
        {
            field: 'action',
            headerName: t("result_table_action"),
            width: 500,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const undo = async (e) => {
                    await axiosPrivate.put("/result/undo", {
                        ids: [params.row.id]
                    })
                    const updatedData = data.filter(item => item.id !== params.row.id);
                    setData(updatedData)
                };

                const archiveResult = async (e) => {
                    await axiosPrivate.put("/result/archive", {
                        ids: [params.row.id]
                    })
                    const updatedData = data.filter(item => item.id !== params.row.id);
                    setData(updatedData)
                };
                const licenseResult = async (e) => {
                    await axiosPrivate.put("/result/license", {
                        ids: [params.row.id]
                    })
                    const updatedData = data.filter(item => item.id !== params.row.id);
                    setData(updatedData)
                };
                // const deleteResult = async (e) => {
                //     await axiosPrivate.put("/result/delete", {
                //         ids: [params.row.id]
                //     })
                //     const updatedData = data.filter(item => item.id !== params.row.id);
                //     setData(updatedData)
                // };

                const pursueResult = async (e) => {
                    await axiosPrivate.put("/result/pursue", {
                        ids: [params.row.id]
                    })
                    const updatedData = data.filter(item => item.id !== params.row.id);
                    setData(updatedData)
                };

                // const addToBlacklist = async (e) => {
                //     await axiosPrivate.post("/blacklist/create", {
                //         url: params.row.resultUrl
                //     })
                //     archiveResult()
                // };
                // const addToWhitelist = async (e) => {
                //     await axiosPrivate.post("/whitelist/create", {
                //         url: params.row.resultUrl
                //     })
                //     archiveResult()
                // };

                return (
                    <React.Fragment>
                        {filter === "none" ?
                            <>
                                {auth?.eligible ? <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={pursueResult}>
                                    {t("results_button_prosecute")}
                                </div> : <></>}
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={archiveResult}>
                                    {t("results_button_delete")}
                                </div>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={licenseResult}>
                                    {t("results_button_license")}
                                </div>
                            </>
                            :
                            <></>
                        }
                        {filter === "archived" ?
                            <>

                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={undo}>
                                    {t("results_button_undo")}
                                </div>
                                {auth?.eligible ? <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={pursueResult}>
                                    {t("results_button_prosecute")}
                                </div> : <></>}
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={licenseResult}>
                                    {t("results_button_license")}
                                </div>
                            </>
                            :
                            <></>
                        }
                        {filter === "pursued" ?
                            <>
                                {/* <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={undo}>
                                    {t("results_button_undo")}
                                </div>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={archiveResult}>
                                    {t("results_button_delete")}
                                </div>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={licenseResult}>
                                    {t("results_button_license")}
                                </div> */}
                            </>
                            :
                            <></>
                        }
                        {filter === "licensed" ?
                            <>

                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={undo}>
                                    {t("results_button_undo")}
                                </div>
                                {auth?.eligible ? <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={pursueResult}>
                                    {t("results_button_prosecute")}
                                </div> : <></>}
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={archiveResult}>
                                    {t("results_button_delete")}
                                </div>
                            </>
                            :
                            <></>
                        }
                        {filter === "deleted" ?
                            <>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={undo}>
                                    {t("results_button_undo")}
                                </div>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={archiveResult}>
                                    {t("results_button_delete")}
                                </div>
                                <div className='mx-1 btn btn-primary' style={{ backgroundColor: "#79E945", color: "#2E451D" }} onClick={licenseResult}>
                                    {t("results_button_license")}
                                </div>
                            </>
                            :
                            <></>
                        }
                    </React.Fragment>
                );
            },
        }
    ];

    return (
        <>
            <div style={{ width: '100%', background: "rgba(255,255,255,.5)", borderRadius: "15px" }}>
                <DataGrid
                    autoHeight
                    rows={data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: pageSize },
                        },
                    }}
                    getRowHeight={() => "auto"}
                    paginationMode='server'
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowCount={pageTotal}
                    onRowSelectionModelChange={(data) => {
                        getSelectedIds(data)
                    }}
                    onPaginationModelChange={(model) => pageChange(model)}
                    slots={
                        {
                            noRowsOverlay: () => (
                                <div className=""></div>
                            ),
                            NoResultsOverlay: () => (
                                <div className=""></div>
                            )
                        }
                    }
                    localeText={{
                        noResultsOverlayLabel: t("no_results"),

                    }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t("rows_per_page"),
                        },
                    }}
                    style={{ border: "none" }}
                />
            </div>
        </>
    );
}

export default ResultsTable;