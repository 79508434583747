import { Outlet } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import NavbarTop from "./NavbarTop";

const Layout = () => {
  return (
    <>
      <div className="wrapper">
        <div className="d-none d-md-block">
          <MainNavigation />
        </div>
        <div className="main">
          <div className="d-md-none">
            <div>
              <NavbarTop />
            </div>
          </div>
          <main className="content m-2 px-3 py-2">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
