import { Route, Routes } from "react-router-dom";

import Activate from "./components/Auth/Activate";
import EnterCode from "./components/Auth/EnterCode";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import SetNewPassword from "./components/Auth/SetNewPassword";
import SetNewPasswordNewCustomer from "./components/Auth/SetNewPasswordNewCustomer";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import "./i18n";
import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import Images from "./pages/Images";
import Results from "./pages/Results";
import Settings from "./pages/Settings";
import "/node_modules/flag-icons/css/flag-icons.min.css";

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="*" element={<Missing />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="activate/:activationCode" element={<Activate />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route path="resetCode" element={<EnterCode />} />
      <Route path="resetPassword/:passwordResetCode" element={<SetNewPassword />} />
      <Route path=":activationCode/setPassword" element={<SetNewPasswordNewCustomer />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="/" element={<Layout />}>
        <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
          <Route path="" element={<Dashboard />} />
          <Route path="results" element={<Results />} />
          <Route path="images" element={<Images />} />
          {/* <Route path="invoices" element={<Invoices />} /> */}
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["admin"]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
