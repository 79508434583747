import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

function UserTable({ users, getSelectedUser, pageChange, page, pageSize, pageTotal }) {

    const { t, i18n } = useTranslation();
    const columns = [
        {
            headerName: 'Action', width: 50, renderCell: (params) => (
                <button style={{ background: "transparent", border: "none" }} onClick={() => getSelectedUser(params.row.id)}><i class="bi bi-search"></i></button>
            )
        },
        { field: 'id', headerName: 'Id', width: 300 },
        { field: 'email', headerName: t("email"), width: 250 },
        { field: 'firstName', headerName: t("firstName"), width: 250 },
        { field: 'lastName', headerName: t("lastname"), width: 250 },
    ];

    return (
        <>
            <div style={{ width: '100%', background: "rgba(255,255,255,.5)", borderRadius: "15px" }}>
                <DataGrid
                    autoHeight
                    rows={users}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: pageSize },
                        },
                    }}

                    paginationMode='server'
                    pageSizeOptions={[5, 10]}
                    rowCount={pageTotal}
                    disableRowSelectionOnClick
                    // onRowSelectionModelChange={(data) => {
                    //     getSelectedUser(data)
                    // }}
                    onPaginationModelChange={(model) => pageChange(model)}
                    slots={
                        {
                            noRowsOverlay: () => (
                                <div className=""></div>
                            ),
                        }
                    }
                    style={{ border: "none" }}
                />
            </div>
        </>
    );
}

export default UserTable;